import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ image, alt, ...props }) => {
  if (!!image && typeof image === 'string') {
    return <img className="image" src={image} alt={alt} />
  } else {
    return <Img className="image" fluid={image.childImageSharp.fluid} {...props} />
  }
}

PreviewCompatibleImage.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
}

export default PreviewCompatibleImage
