import React from "react"
import { Helmet } from 'react-helmet'

const FacebookFeed = () => (
  <div className="fb-page"
       data-href="https://www.facebook.com/amberleyfarmersmarket.org.nz/"
       data-tabs="timeline"
       data-width="500"
       data-height=""
       data-small-header="true"
       data-adapt-container-width="true"
       data-hide-cover="true"
       data-show-facepile="true">
    <Helmet>
      {/* Insert the Icon Of Sin */}
      <script
        async={true}
        defer={true}
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v8.0"
        nonce="VmkfISYM"/>
    </Helmet>
    <blockquote cite="https://www.facebook.com/amberleyfarmersmarket.org.nz/"
                className="fb-xfbml-parse-ignore">
      <a href="https://www.facebook.com/amberleyfarmersmarket.org.nz/">Amberley Farmers Market</a>
    </blockquote>
  </div>
)
export default FacebookFeed
