import React from 'react'
import PropTypes from 'prop-types'
import VendorTile from '../components/VendorTile'

const VendorGrid = ({vendors}) => {
  return (
    <div role="menu"
         className="vendor-grid">
      {vendors.map(({node: vendor}, index) => (
        <VendorTile key={vendor.frontmatter.title}
                    title={vendor.frontmatter.title}
                    photo={vendor.frontmatter.photo}
                    description={vendor.html}/>
      ))}
    </div>
  )
}

VendorGrid.propTypes = {
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        photo: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      }),
      html: PropTypes.string,
    })
  ),
}

export default VendorGrid
