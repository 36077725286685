import React from 'react'
import PropTypes from 'prop-types'
import { HTMLContent } from "./Content"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Tappable from 'react-tappable';

const VendorTile = ({title, photo, description}) => {
  const [isShown, setIsShown] = React.useState(false);

  const onShow = React.useCallback(() => setIsShown(true), []);
  const onHide = React.useCallback(() => setIsShown(false), []);

  const overlay = isShown ? "vendor-overlay-full" : "vendor-overlay-short";

  return (
    <Tappable
      component="div"
      className="vendor-item"
      role="menuitem"
      onMouseEnter={onShow}
      onMouseLeave={onHide}
      onFocus={onShow}
      onBlur={onHide}
      onTap={onShow}
    >
      <PreviewCompatibleImage
        image={photo}
        alt={`Photo for ${title}`}
        loading="eager"
      />
      <span className={`vendor-overlay ${overlay}`}/>
      <span className={`vendor-label ${isShown ? "fade-out" : "fade-in"}`}
            aria-hidden={isShown}>
        {title}
      </span>
      <span className={`vendor-label ${isShown ? "fade-in" : "fade-out"}`}
            aria-hidden={!isShown}>
        <HTMLContent content={description}/>
      </span>
    </Tappable>
  )
}

VendorTile.propTypes = {
  vendor: PropTypes.shape({
    title: PropTypes.string,
    photo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    description: PropTypes.string
  })
}

export default VendorTile
