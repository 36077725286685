import { useStaticQuery, graphql } from "gatsby"

export const useRegularVendors = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query RegularVendors {
        allMarkdownRemark(filter: {frontmatter: {dataKey: {eq: "vendor"},
                                                 regularity: {eq: "regular"}}},
                          sort: {fields: frontmatter___first, order: DESC}) {
          edges {
            node {
              html
              frontmatter {
                title
                photo {
                  ...vendorImage
                }
              }
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark.edges
}
