import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import VendorGrid from '../components/VendorGrid'
import StreetMap from '../components/StreetMap'
import FacebookFeed from '../components/FacebookFeed'
import useSiteMetadata from '../hooks/use-site-metadata'
import { useRegularVendors } from "../hooks/use-regular-vendors"
import { useSeasonalVendors } from "../hooks/use-seasonal-vendors"
import { useGuestVendors } from "../hooks/use-guest-vendors"

export const IndexPageTemplate = ({
  image,
  subheading,
  body,
  manager
}) => {
  const { title } = useSiteMetadata()
  return (
    <div>
      <header className="section has-text-centered">
        <div className="columns">
          <div className="column">
            <h1 className="title is-1">
              <Img fixed={image.fixed}
                   alt={`${title} logo`}
                   loading="eager"
              />
            </h1>
            <h2 className="subtitle is-3">{subheading}</h2>
          </div>
        </div>
      </header>
      <section className="hero has-text-centered">
        <div className="hero-body">
          <div className="container content">
            <div className="columns is-vcentered">
              <div className="column has-text-weight-medium"
                   style={{textAlign: "justify"}}>
                <HTMLContent content={body}/>
                <p>
                  {manager.name}<br/>
                  {manager.title}<br/>
                  <a href={`tel:${manager.phone}`}>
                    {manager.phone}
                  </a><br/>
                  <a href={`mailto:${manager.email}`}>
                    {manager.email}
                  </a>
                </p>
              </div>
              <div className="column">
                <StreetMap/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section vendor-section has-text-centered">
        <div className="container">
          <h1 className="title is-1">The Regulars</h1>
          <VendorGrid vendors={useRegularVendors()}/>
        </div>
      </section>
      <section className="section vendor-section has-text-centered">
        <div className="container">
          <h1 className="title is-1">Seasonal Vendors</h1>
          <VendorGrid vendors={useSeasonalVendors()}/>
        </div>
      </section>
      <section className="section vendor-section has-text-centered">
        <div className="container">
          <h1 className="title is-1">Guest Stalls</h1>
          <VendorGrid vendors={useGuestVendors()}/>
        </div>
      </section>
      <section className="section"
               style={{textAlign:"center"}}>
        <h1 className="title is-1">News via Facebook</h1>
        <FacebookFeed/>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        body={html}
        image={frontmatter.image.childImageSharp}
        subheading={frontmatter.subheading}
        manager={frontmatter.manager}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        subheading
        image {
          childImageSharp {
            fixed(width: 360) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        manager {
          name
          title
          phone
          email
        }
      }
    }
  }
`
